import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonContainer } from '../../utils/CommonFunction';
import { blogPostService } from './Service';
import { setSelectedRowData, toggleLoadingView, toggleToastView } from '../../redux/reducer/appReducer';
import { blogPostUrlConstants } from './Constants';
import { getTableData } from '../tableComponent/tableComponent.slice';
import { imageUpload } from "../../utils/helper";

export default function BlogPostContainer() {
  const { showEditForm, showAddForm, showViewForm, selectedRowData } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState("1");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [fileTypeToRemove, setFileTypeToRemove] = useState(null);
  const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } = CommonContainer();

  const blogPostInfo = {
    type: "",
    title: "",
    shortDescription: "",
    image: "",
    blogDetailImage: "",
    desc: "",
    active: true
  };
  const [resetData, setResetData] = useState(blogPostInfo);
  const [rowData, setRowData] = useState(blogPostInfo);
  const [imageToRemove, setImageToRemove] = useState([]);

  const getSelectedRowData = async () => {
    dispatch(toggleLoadingView(true));
    const data = await blogPostService.getSelectedBlogPostService(selectedRowData?._id);
    setRowData(data?.result);
    dispatch(toggleLoadingView(false));
  };

  useEffect(() => {
    if (showViewForm || showEditForm) {
      getSelectedRowData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleEditorChange = (content) => {
    setRowData((prevData) => ({
      ...prevData,
      desc: content,
    }));
  };

  const onChangeCategory = (event) => {
    setRowData((prevData) => ({
      ...prevData,
      type: event.target.value,
    }));
  };

  const onChangeTitle = (event) => {
    setRowData((prevData) => ({
      ...prevData,
      title: event.target.value,
    }));
  };

  const onChangeShortDesc = (event) => {
    setRowData((prevData) => ({
      ...prevData,
      shortDescription: event.target.value,
    }));
  };

  const onChangeDesc = (event) => {
    setRowData((prevData) => ({
      ...prevData,
      desc: event.target.value,
    }));
  };
  
  const onChangeStatus = (event) => {
    setRowData({ ...rowData, active: event.target.value });
  };
  const onChangeImage = async (event) => {
    dispatch(toggleLoadingView(true));
    const res = await imageUpload(event);
    if (res.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "Please upload again",
          isError: true,
        })
      );
    } else {
      setRowData((prevData) => ({
        ...prevData,
        image: res?.result?.image,
      }));
    }
    dispatch(toggleLoadingView(false));
  };

  const onChangeBlogDetailImage = async (event) => {
    dispatch(toggleLoadingView(true));
    const res = await imageUpload(event);
    if (res.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "Please upload again",
          isError: true,
        })
      );
    } else {
      setRowData((prevData) => ({
        ...prevData,
        blogDetailImage: res?.result?.image,
      }));
    }
    dispatch(toggleLoadingView(false));
  };

  const onRemoveFile = async (fileType) => {
    const file = rowData[fileType];
    const updatedImageToRemove = [...imageToRemove, { [fileType]: file }];
    setImageToRemove(updatedImageToRemove);

    setRowData((prevData) => ({
      ...prevData,
      [fileType]: "",
    }));
  };

  const handleRemoveClick = (type) => {
    setFileTypeToRemove(type);
    setConfirmationDialogOpen(true);
  };
  

  const handleRemoveConfirm = () => {
    onRemoveFile(fileTypeToRemove);
    setConfirmationDialogOpen(false);
  };

  const handleRemoveCancel = () => {
    setConfirmationDialogOpen(false);
  };

  const onClickReset = () => {
    setRowData(resetData);
  };

  const onClickBlogPostViewForm = () => {
    onClickViewForm();
  };

  const onClickBlogPostAddForm = () => {
    onClickAddForm();
  };

  const onClickBlogPostEditForm = () => {
    onClickEditForm();
  };

  const onClickSave = async () => {
    if (
      rowData?.type.trim() === "" ||
      rowData?.title.trim() === "" ||
      rowData?.shortDescription.trim() === "" ||
      rowData?.image.trim() === "" ||
      rowData?.blogDetailImage.trim() === "" ||
      rowData?.desc.trim() === ""
    ) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "* Please fill the required fields",
          isError: true,
        })
      );
    } else {
      dispatch(toggleLoadingView(true));
      let response;
      if (showAddForm) {
        response = await blogPostService.saveBlogPostDataService(rowData);
      } else {
        response = await blogPostService.updateBlogPostDataService(rowData);
      }
      if (response.result.length === 0) {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: true,
          })
        );
      } else {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: false,
          })
        );
        await dispatch(getTableData(blogPostUrlConstants.getTableDataUrl));
        onClickShowTable();
      }
      dispatch(toggleLoadingView(false));
    }
  };

  const onClickDelete = async () => {
    dispatch(toggleLoadingView(true));
    const response = await blogPostService.deleteSelectedBlogPostService(selectedRowData?._id);
    dispatch(setSelectedRowData({}));
    if (response.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: response.message,
          isError: true,
        })
      );
    } else {
      dispatch(
        toggleToastView({
          showToast: true,
          message: response.message,
          isError: false,
        })
      );
      await dispatch(getTableData(blogPostUrlConstants.getTableDataUrl));
    }
    dispatch(toggleLoadingView(false));
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    onClickDelete();
    setShowDeleteConfirmation(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  return {
    tabIndex,
    onChangeTabIndex,
    showViewForm,
    rowData,
    onClickBlogPostViewForm,
    onClickBlogPostAddForm,
    onClickBlogPostEditForm,
    onClickDelete,
    onClickSave,
    onClickReset,
    handleDeleteClick,
    handleDeleteConfirm,
    handleDeleteCancel,
    showDeleteConfirmation,
    onChangeCategory,
    onChangeTitle,
    onChangeShortDesc,
    onChangeDesc,
    onChangeStatus,
    onChangeImage,
    onChangeBlogDetailImage,
    onRemoveFile,
    handleRemoveClick,
    handleRemoveConfirm,
    handleRemoveCancel,
    handleEditorChange,
    confirmationDialogOpen
  };
}
