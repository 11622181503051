import { blogPostLabels } from "../../assets/labels/blogPostLabels";
import { getDateTimeFormat } from "../helper";

export const blogPostColumns = () => {

	const blog_post_columns = [
		{
			field: blogPostLabels.blog_title_field,
			headerName: blogPostLabels.blog_title,
		},
		{
			field: blogPostLabels.category_field,
			headerName: blogPostLabels.category,
		},
        {
			field: blogPostLabels.short_description_field,
			headerName: blogPostLabels.short_description,
		},
		// {
		// 	field: blogPostLabels.created_date_field,
		// 	headerName: blogPostLabels.created_date,
		// },
		// {
		// 	field: blogPostLabels.status_field,
		// 	headerName: blogPostLabels.status,
		// },
		{
			field: blogPostLabels.created_date_field,
			headerName: blogPostLabels.created_date,
			// filter: 'agDateColumnFilter',
			//  // add extra parameters for the date filter
			//  filterParams: {
			// 	// provide comparator function
			// 	comparator: (filterLocalDateAtMidnight, cellValue) => {
			// 		const dateAsString = cellValue;
	
			// 		if (dateAsString == null) {
			// 			return 0;
			// 		}
	
			// 		// In the example application, dates are stored as dd/mm/yyyy
			// 		// We create a Date object for comparison against the filter date
			// 		const dateParts = dateAsString.split('/');
			// 		const year = Number(dateParts[2]);
			// 		const month = Number(dateParts[1]) - 1;
			// 		const day = Number(dateParts[0]);
			// 		const cellDate = new Date(year, month, day);
	
			// 		// Now that both parameters are Date objects, we can compare
			// 		if (cellDate < filterLocalDateAtMidnight) {
			// 			return -1;
			// 		} else if (cellDate > filterLocalDateAtMidnight) {
			// 			return 1;
			// 		}
			// 		return 0;
			// 	}
			// },
			valueFormatter: (params) => getDateTimeFormat(params.value),
		},
		{
			field: blogPostLabels.status_field,
			headerName: blogPostLabels.status,
			valueFormatter: (params) => (params.value ? "Active" : "Inactive"),
		  },
		

	];

	return blog_post_columns;
};
