export const blogPostLabels = {

	blog_title_field: "title",
	blog_title: "Blog Title",

	short_description_field: "shortDescription",
	short_description: "Short Description",

    category_field: "type",
	category: "Category",

    desc_field: "desc",
	desc: "Description *",

    image_field: "image",
    image: "Upload Blog Image",

	blog_detail_image_field: "blogDetailImage",
    blog_detail_image: "Upload Blog Detail Image",

	created_date_field: 'updatedAt',
	created_date: 'Created Date',

	status_field: 'active',
	status: 'Status'

};
