export const companyProjectLabels = {
	company_name_field: "name",
	company_name: "Project Name",

	shortDescription: "Short Description",

	description_field: "description",
	description: "Description",

	companyId_filed: "companyName",
	companyId: "Company",

	company_project_status_field: "delete",
  	company_project_status: "Status",

	upload_main_image_field: "",
	upload_main_image: "Upload Main Image",

	upload_more_images_field: "",
	upload_more_images: "Upload More Images",

	android_JSON_files: "Upload Android JSON Files",
	android_hash_files: "Upload Android Hash Files",
	android_bundle_files: "Upload Android Bundle Files",

	ios_JSON_files: "Upload ios JSON Files",
	ios_hash_files: "Upload ios Hash Files",
	ios_bundle_files: "Upload ios Bundle Files",

	windows_JSON_files: "Upload Windows JSON Files",
	windows_hash_files: "Upload Windows Hash Files",
	windows_bundle_files: "Upload Windows Bundle Files",

	webgl_JSON_files: "Upload Webgl JSON Files",
	webgl_hash_files: "Upload Webgl Hash Files",
	webgl_bundle_files: "Upload Webgl Bundle Files",

	mac_JSON_files: "Upload Mac JSON Files",
	mac_hash_files: "Upload Mac Hash Files",
	mac_bundle_files: "Upload Mac Bundle Files",

	android_files_title: "ANDROID FILES",
	ios_files_title: "IOS FILES",
	windows_files_title: "WINDOWS FILES",
	webgl_files_title: "WEBGL FILES",
	mac_files_title: "MAC FILES",

	scene_guide: "Scene Guid",
	agora_id: "AgoraId",
	photonId_id: "PhotonId",
	photon_settings: "Photon Settings",
	app_id_real_time: "App Id Realtime",
	app_id_fusion: "App Id Fusion",
	app_id_chat: "App Id Chat",
	app_id_voice: "App Id Voice",
	app_version: "App Version",
	user_name_server: "User Name Server",
	fixed_region: "Fixed Region",
	server: "Server",
	port: "Port",
	proxy_server: "Proxy Server",
	protocol: "Protocol",
	enable_protocal_fallback: "Enable Protocal Fallback",
	auth_mode: "Auth Mode",
	enable_lobby_statistics: "Enable Lobby Statistics",
	network_logging: "Network Logging",

	more_images: "More Images",
	main_images: "Main Images",

	android_json_files_title: "Android JSON Files",
	android_hash_files_title: "Android Hash Files",
	android_bundle_files_title: "Android Bundle Files",

	ios_json_files_title: "IOS JSON Files",
	ios_hash_files_title: "IOS Hash Files",
	ios_bundle_files_title: "IOS Bundle Files",

	windows_json_files_title: "Windows JSON Files",
	windows_hash_files_title: "Windows Hash Files",
	windows_bundle_files_title: "Windows Bundle Files",

	webgl_json_files_title: "Webgl JSON Files",
	webgl_hash_files_title: "Webgl Hash Files",
	webgl_bundle_files_title: "Webgl Bundle Files",

	mac_json_files_title: "Mac JSON Files",
	mac_hash_files_title: "Mac Hash Files",
	mac_bundle_files_title: "Mac Bundle Files",
};
