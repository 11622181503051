import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRowData,
  toggleLoadingView,
} from "../../redux/reducer/appReducer";
import { useCallback, useEffect, useRef, useState } from "react";
import { getTableData } from "./tableComponent.slice";
import { apiStatus } from "../../utils/globalConstant";

export const TableContainer = ({ columnFields, url, statusf, subscriptionStatusF, roleF}) => {
  const dispatch = useDispatch();
  const { status, data: tableData } = useSelector(
    (state) => state.table.tableData
  );
  const { selectedRowData } = useSelector((state) => state.app);

  const gridRef = useRef();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columnDefs, setColumnDefs] = useState(columnFields);
  const [statusFilter, setStatusFilter] = useState(statusf);
  const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useState(subscriptionStatusF);
  const [roleFilter, setRoleFilter] = useState(roleF); // Default to "All" roles
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if ([apiStatus.success, apiStatus.failed].includes(status)) {
      dispatch(toggleLoadingView(false));
    } else {
      dispatch(toggleLoadingView(true));
    }
  }, [status, dispatch]);

  useEffect(() => {
    dispatch(setSelectedRowData({}));
    dispatch(toggleLoadingView(true));
    dispatch(getTableData(url));
  }, [url, dispatch]);

  useEffect(() => {
    if (headerNames.includes("Status") || headerNames.includes("Subscription Status") || headerNames.includes("Role")) {
      filterData();
    } else {
      setFilteredData(tableData);
    }
  }, [statusFilter, subscriptionStatusFilter, roleFilter, tableData]);

  const filterData = () => {
    let filtered = tableData;
    if (headerNames.includes("Status")) {
      filtered = filtered.filter(row => row.active === statusFilter);
    }
    if (headerNames.includes("Subscription Status")) {
      filtered = filtered.filter(row => row.subscriptionStatus === subscriptionStatusFilter);
    }
    if (headerNames.includes("Role") && roleFilter !== "All") {
      filtered = filtered.filter(row => row.role === roleFilter);
    }
    setFilteredData(filtered);
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const onPageSizeChanged = useCallback((event) => {
    let rowCount = event.target.value;
    setRowsPerPage(Number(rowCount));
  }, []);

  const onSelectionChanged = (e) => {
    const selectedRow = e.api.getSelectedRows()[0];
    dispatch(setSelectedRowData(selectedRow));
  };

  const onChangeTextFilter = (event) => {
    gridRef.current.api.setQuickFilter(event.target.value);
  };

  const headerNames = columnFields.map((each) => each.headerName);

  const [selectedFields, setSelectedFields] = useState(headerNames);

  const onChangeSelectedColumnFields = (event) => {
    const {
      target: { value },
    } = event;

    let columnFieds = columnFields.filter((each) =>
      value.includes(each.headerName)
    );
    setColumnDefs(columnFieds);
    setSelectedFields(typeof value === "string" ? value.split(",") : value);
  };

  const onStatusFilterChange = (event) => {
    const value = event.target.value;
    setStatusFilter(value === 'true');
  };

  const onSubscriptionStatusFilterChange = (event) => {
    setSubscriptionStatusFilter(event.target.value);
  };

  const onRoleChangeFilter = (event) => {
    setRoleFilter(event.target.value);
  };

  return {
    tableData: filteredData,
    defaultColDef,
    gridRef,
    rowsPerPage,
    onPageSizeChanged,
    onSelectionChanged,
    onChangeSelectedColumnFields,
    selectedFields,
    columnDefs,
    headerNames,
    selectedRowData,
    onChangeTextFilter,
    statusFilter,
    subscriptionStatusFilter,
    onStatusFilterChange,
    onSubscriptionStatusFilterChange,
    onRoleChangeFilter,
    roleFilter
  };
};
